<div class="wrapper">
    <app-menu></app-menu>
<!--Pagina 3-->
    <div class="container">
        <div class="row">
            <div id="precio" class="precio">
                <div class="col-md-12 text-center">
                    <h2 class="titleSM-center"><b>PRECIO</b></h2>
                    <!--<img class="iconos" src="assets/Iconos/Precio.svg">-->
                </div>

                <div class="row justify-content-center">
                    <div class="col-md-4 row precio-item-container justify-content-center">
                        <div class="card">
                            <div class="card-header precio-header">
                                MENSUAL
                            </div>
                            <div class="card-body">
                                <img class="iconos" src="assets/Iconos/Usuario.svg">
                                <h5 class="card-title">$7</h5>
                                <p class="card-text">/alumno</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 row precio-item-container justify-content-center">
                        <div class="card active">
                            <div class="card-header precio-header">
                                SEMESTRAL
                            </div>
                            <div class="card-body">
                                <img class="iconos" src="assets/Iconos/Usuario.svg">
                                <h5 class="card-title">$40</h5>
                                <p class="card-text">/alumno</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 row precio-item-container justify-content-center">
                        <div class="card">
                            <div class="card-header precio-header">
                                ANUAL
                            </div>
                            <div class="card-body">
                                <img class="iconos" src="assets/Iconos/Usuario.svg">
                                <h5 class="card-title">$64</h5>
                                <p class="card-text">/alumno</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row text-center">
                    <h2 class="disclaimer">* Todos los paquetes incluyen todos los servicios de las plataformas y futuras actualizaciones.</h2>
                </div>

                <div class="row mb-3">
                    <div class="col-md-12 text-center">
                        <h2 class="titleSM-center"><b>CONTACTANOS Y AGENDA TU DEMO</b></h2>
                    </div>
                    <div class="col-md-12 text-center">
                        <b>CORREO: </b><br>mauro.sosa@gmstore.com.mx
                    </div>
                    <div class="col-md-12 text-center">
                        <b>WHATSAPP: </b><br>+52 618 228 5268
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
<div class="wrapper">
    <app-menu></app-menu>
    <!--Pagina 2-->
    <div class="container space">
        <div class="row">
            <div id="caracteristicas" class="caracteristicas">
                <div class="col-md-12 text-center encabezado">
                    <h2 class="titleSM-center"><b>SERVICIOS INCLUIDOS</b></h2>
                    <img class="caracter" src="assets/Iconos/Multidevices.svg">
                </div>

                <div class="pagina_institucional">
                    <div class="col-md-12">
                        <h2 class="titleSM-start"><b>Página Institucional</b></h2>
                    </div>

                    <div class="row caracteristicas_container justify-content-center">
                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Configurable.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Diseño personalizado</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/WEB.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Dominio edu.mx</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="panel_administrativo space">
                    <div class="col-md-12">
                        <h2 class="titleSM-start"><b>Panel Administrativo</b></h2>
                    </div>

                    <div class="row caracteristicas_container justify-content-center">

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Usuario.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Administracion de Alumnos</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Usuario.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Administracion de Usuarios</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Usuario.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Administracion de Docentes</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Credencial.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Credencialización</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/PDF.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Justificantes</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/PDF.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Reportes Disciplinarios</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/PDF.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Reportes Calificaciones</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/PDF.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Boleta de Calificaciones</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Examen.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Examenes Extraordinarios</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Examen.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Examenes Intersemestrales</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Excel.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Carga de Calificaciones (SISEMS)</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Tarjeta.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Pago en Linea</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Telegram.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Mensajeria por Telegram</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Diagrama.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Indices de Reprobación</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Diagrama.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Indices de Aprobación</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/GPS.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Asistencia GPS</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="panel_alumnos space">
                    <div class="col-md-12">
                        <h2 class="titleSM-start"><b>Panel Alumnos</b></h2>
                    </div>

                    <div class="row caracteristicas_container justify-content-center">
                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Usuario.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Edición de Datos Personales</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/PDF.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Reporte de Calificaciones</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Tarjeta.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Pago en Linea</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="preinscripciones space">
                    <div class="col-md-12">
                        <h2 class="titleSM-start"><b>Sistema de Preinscripciones</b></h2>
                    </div>

                    <div class="row caracteristicas_container justify-content-center">

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/WEB.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Preinscripciones en Linea</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/WEB.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Consulta de Aceptados Ceneval</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Configurable.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Administracion de Periodos de Inscripcion</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/Excel.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Reporte de Inscritos</p>
                                </div>
                            </div>
                        </div>

                        <div class="caracteristicas_elemento col-md-2 row">
                            <div class="contenedor_elemento">
                                <div class="col-md-12 text-center">
                                    <img class="iconos" src="assets/Iconos/PDF.svg" />
                                </div>
                                <div class="col-md-12 text-center space">
                                    <p>Reporte de Inscritos</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>
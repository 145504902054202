<!--Pagina 1-->
<div class="wrapper">
    <app-menu></app-menu>
    <div class="container">
        <div class="banner row">
            <div class="col-md-6">
                <carousel [interval]="3000" [isAnimated]="true">
                    <slide>
                        <img src="assets/Banners/BAN1.webp">
                    </slide>
                    <slide>
                        <img src="assets/Banners/BAN2.webp">
                    </slide>
                    <slide>
                        <img src="assets/Banners/BAN3.webp">
                    </slide>
                </carousel>
            </div>
            <div class="col-md-6 space">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h1 class="titleSM-start"><b>"Soluciones innovadoras para una educación moderna"</b></h1>
                    </div>
                    <div class="col-md-12 text-center">
                        <img class="iconos" src="assets/Iconos/Educacion.svg">
                    </div>
                    <div class="col-md-12 text-justify">
                        <h3><b>¿Qué es SmartSchool?</b></h3>
                    </div>
                    <div class="col-md-12 justificado">
                        <p class="text-justify">En SmartSchool, estamos comprometidos con el éxito de tus estudiantes y la
                            evolución de la
                            educación. Nuestra plataforma te ofrece herramientas innovadoras para que los estudiantes
                            aprovechen
                            al máximo su estancia en la institución con procesos rápidos y que los padres se involucren de
                            manera más activa en el proceso educativo. Con SmartSchool, podrás acceder a herramientas de alta
                            calidad y rastrear el progreso de tus estudiantes.
                        </p>
                        <p class="text-center">
                            Descubre cómo SmartSchool puede transformar
                            tu
                            experiencia
                            educativa y llevarte al éxito en el mundo moderno.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="nuestras_plataformas space">
            <div class="row justify-content-center">
    
                <div class="col-md-12 text-center">
                    <h2 class="titleSM-center"><b>PLATAFORMAS INCLUIDAS</b></h2>
                </div>
    
                <div class="col-md-12 row justify-content-center">
    
                    <div class="plataforma_elemento col-md-4 row">
                        <div class="contenedor_elemento">
                            <div class="col-md-12 text-center">
                                <img class="iconos" src="assets/Iconos/WEB.svg" />
                            </div>
                            <div class="col-md-12 text-center space">
                                <p>Página Institucional</p>
                            </div>
                        </div>
                    </div>
    
                    <div class="plataforma_elemento col-md-4 row">
                        <div class="contenedor_elemento">
                            <div class="col-md-12 text-center">
                                <img class="iconos" src="assets/Iconos/WEB.svg" />
                            </div>
                            <div class="col-md-12 text-center space">
                                <p>Panel Administrativo</p>
                            </div>
                        </div>
                    </div>
    
                    <div class="plataforma_elemento col-md-4 row">
                        <div class="contenedor_elemento">
                            <div class="col-md-12 text-center">
                                <img class="iconos" src="assets/Iconos/WEB.svg" />
                            </div>
                            <div class="col-md-12 text-center space">
                                <p>Panel Alumnos</p>
                            </div>
                        </div>
                    </div>
    
                    <div class="plataforma_elemento col-md-4 row space">
                        <div class="contenedor_elemento">
                            <div class="col-md-12 text-center">
                                <img class="iconos" src="assets/Iconos/WEB.svg" />
                            </div>
                            <div class="col-md-12 text-center space">
                                <p>Sistema de Preinscripciones</p>
                            </div>
                        </div>
                    </div>
    
                    <div class="plataforma_elemento col-md-4 row space">
                        <div class="contenedor_elemento">
                            <div class="col-md-12 text-center">
                                <img class="iconos" src="assets/Iconos/Android.svg" />
                            </div>
                            <div class="col-md-12 text-center space">
                                <p>Applicacion Movil Android</p>
                            </div>
                        </div>
                    </div>
    
                    <div class="plataforma_elemento col-md-4 row space">
                        <div class="contenedor_elemento">
                            <div class="col-md-12 text-center">
                                <img class="iconos" src="assets/Iconos/IPhone.svg" />
                            </div>
                            <div class="col-md-12 text-center space">
                                <p>Applicacion Movil IOS</p>
                            </div>
                        </div>
                    </div>
    
                </div>
    
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//Components
import { DashboardComponent } from './dashboard/dashboard.component';
import { CaracteristicasComponent } from './caracteristicas/caracteristicas.component';
import { PrecioComponent } from './precio/precio.component';

const routes: Routes = [
  { path: "", component: DashboardComponent },
  { path: "caracteristicas", component: CaracteristicasComponent },
  { path: "precio", component: PrecioComponent },
  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', component: DashboardComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash : true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { GlobalConstant } from '../utils/GlobalConstant';
import { Router } from '@angular/router';
import { GlobalEnviroments } from '../utils/GlobalEnvirontment';
import { MatDialog } from '@angular/material/dialog';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { CollapseDirective } from 'ngx-bootstrap/collapse';
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  LOGO_PLATAFORMA: any;
  NOMBRE_PLATAFORMA: any;
  NOMBRE_ESCUELA: any;
  isMenuPrincipal: boolean;
  isMenuCarreras: boolean;
  constructor(private scroll: ViewportScroller, router: Router, public dialog: MatDialog) {
    this.LOGO_PLATAFORMA = GlobalConstant.LOGO_PLATAFORMA;
    this.NOMBRE_ESCUELA = GlobalConstant.NOMBRE_ESCUELA
    this.NOMBRE_PLATAFORMA = GlobalConstant.NOMBRE_PLATAFORMA
    this.isMenuPrincipal = true;
    this.isMenuCarreras = true;
  }

  ngOnInit(): void {

  }

  colapsadoMovil(menu: CollapseDirective): void {
    if (menu.isCollapsed) {
      this.isMenuPrincipal = false;
    } else {
      this.isMenuPrincipal = true;
    }
  }

  colapsadoCarreras(menu: CollapseDirective): void {
    if (menu.isCollapsed) {
      this.isMenuCarreras = false;
    } else {
      this.isMenuCarreras = true;
    }
  }

  menuManager(lista: HTMLUListElement, state: number): void {
    if (state == 1) {
      lista?.classList.add("show");
    } else if (state == 0) {
      lista?.classList.remove("show");
    }
  }

  currentState(lista: HTMLElement, clase: string): Boolean {
    return lista?.classList.contains(clase);
  }

  ToScroll(id: string): void {
    this.scroll.scrollToAnchor(id);
  }
}

export class GlobalConstant {

    public static HOST_API = "https://plataforma.cbtis130.edu.mx";
    public static BUCKET_AWS = "https://cbtis130.s3.us-west-2.amazonaws.com";
    //public static HOST_API = "http://192.168.1.181:8000";

    public static NOMBRE_PLATAFORMA: string = "SmartSchool";
    public static NOMBRE_ESCUELA: string = "Centro de Bachillerato Tecnologico Industrial y de Servicio #130";
    public static LOGO_PLATAFORMA: string = "assets/LogoGM.svg";

}
<div class="menuBar">
    <nav class="navbar navbar-expand-lg bg-body-tertiary row">
        <div class="container-fluid">
            <button class="navbar-toggler" [ngClass]="{'active': currentState(navbar,'show')}" type="button"
                (click)="colapsadoMovil(navMovil);" aria-controls="navbar">
                <div class="navbar-toggler-ico"></div>
            </button>
            <a class="navbar-brand m-auto m-lg-0" href="#">
                <img src="assets/LogoGM.svg" class="logo">
                <span class="plataformaNombre">{{NOMBRE_PLATAFORMA}}</span>
            </a>
            <div class="collapse navbar-collapse justify-content-lg-end menu-opciones" id="navbar"
                #navMovil="bs-collapse" [collapse]="isMenuPrincipal" [isAnimated]="true" #navbar>
                <ul class="navbar-nav mx-auto me-lg-0 mb-2 mb-lg-0">
                    <li class="nav-item option">
                        <a class="nav-link opText" aria-current="page" href="#/">Inicio</a>
                    </li>
                    <li class="nav-item option">
                        <a class="nav-link opText" aria-current="page" href="#/caracteristicas">Servicios Incluidos</a>
                    </li>
                    <li class="nav-item option">
                        <a class="nav-link opText" aria-current="page" href="#/precio">Precio</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
<ngx-spinner bdColor="rgba(51,51,51,1)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Cargando...</p>
</ngx-spinner>